// /* src/App.js */



import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Beta from './beta/beta';
import './App.css';

function MainPage() {
  return (
    <div>
      <header>
        <h1>Udra</h1>
      </header>
      <div className="banner">
          {/* The banner image will be set using CSS */}
      </div>
      <section className="links">
          <div className="about-us">
              <h2>About Us</h2>
              <ul>
                  <li><a href="#">Our Story</a></li>
                  <li><a href="#">Team</a></li>
                  <li><a href="#">Contact</a></li>
              </ul>
          </div>
          <div className="products">
              <h2>Hydration Tracking</h2>
              <ul>
                  <li><Link to="/beta">Beta Test</Link></li>
              </ul>
          </div>
      </section>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div>
        {/* Navigation links */}
        <Link className="topButtons" to="/">Home</Link>
        <Link className="topButtons" to="/beta">Beta</Link>

        {/* Routes */}
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/beta" element={<Beta />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;






















//Old Stuff from July 2023


// import React, { useEffect, useState } from 'react'
// import { Amplify, API, graphqlOperation } from 'aws-amplify'
// import { createTodo } from './graphql/mutations'
// import { listTodos } from './graphql/queries'
// import { withAuthenticator, Button, Heading, Text, TextField, View } from '@aws-amplify/ui-react';
// import '@aws-amplify/ui-react/styles.css';
// import './App.css';
// //import logo from './logo.svg';
// import Chart from 'chart.js/auto';
// import { Line } from "react-chartjs-2";
// import moment from 'moment'; // Import the date library
// import 'chartjs-adapter-moment'; // Import the adapter for Moment.js
// import beta from '.beta/beta';

// import awsExports from "./aws-exports";
// Amplify.configure(awsExports);

// const initialState = { name: '', description: '' }

// var idValue = '';
// var isoString_from_date = '';
// var isoString_now = '';

// var isFirstRun = true;

// var waterQTY = 0;
// var goalQTY = 0;

// const apiStringRead = {
//   "operation": "read",
//   "payload": {
//     "Key": {
//       "id": idValue
//     }
//   }
// };

// const apiStringPut = {
//   "operation": "create",
//   "payload": {
//     "Item": {
//       "id": idValue,
//       "dt": isoString_now,
//       "quantity": waterQTY
//     }
//   }
// };

// const api_query_user_since_date = {
//   "operation": "query",
//   "payload": {
//       "TableName": "udra-one",
//       "KeyConditionExpression": "#partitionKey = :partitionValue AND #sortKey >= :sortValue",
//       "ExpressionAttributeNames": {
//           "#partitionKey": "id",
//           "#sortKey": "dt"
//       },
//       "ExpressionAttributeValues": {
//           ":partitionValue": `${idValue}`,
//           ":sortValue": `${isoString_from_date}` 
//       },
//       "ConsistentRead": true
//   }
// };

// const apiReadUserSettings = {
//   "operation": "readSettings",
//   "payload": {
//       "Key": {
//           "id": idValue
//       }
//   }
// }

// const apiUpdateUserSettings = {
//   "operation": "updateSettings",
//   "payload": {
//     "Item": {
//       "id": idValue,
//       "goal": waterQTY,
//       "container": waterQTY
//     }
//   }
// }

// const options = {
//   scaleShowValues: false,
//   scales: {
//     x: {
//       type: 'time',
//       time: {
//         unit: 'day', // Customize the display unit (e.g., 'day', 'week', 'month')
//         displayFormats: {
//           day: 'MM-DD' // Customize the date format
//         }
//       },
//       ticks: {
//         //maxRotation: 0, // Prevent label rotation if needed
//         //autoSkip: false,
//         //padding: 2
//       }
//     }
//   }
// };


// function UnderConstructionView() {
//   return (
//     <div>
//       <h1>This site is under construction.</h1>
//     </div>
//   )
// }

// function TrimName(nameString) {
//   if (nameString.endsWith(" ")) {
//     nameString = nameString.trim();
//   }
  
//   return nameString;
// }

// async function QueryOnUser(passedName, passedDate) {
//   api_query_user_since_date.payload.ExpressionAttributeValues[":partitionValue"] = passedName;
//   api_query_user_since_date.payload.ExpressionAttributeValues[":sortValue"] = passedDate;

//   //Query all Entries from User since Time X
//   var response = await fetch('https://w75zszy1n7.execute-api.us-east-2.amazonaws.com/test/dynamodbmanager', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(api_query_user_since_date)
//     }); 

//     const jsonData = await response.json();
//     const itemCount = jsonData.Count;
//     const jsonItems = jsonData.Items;

//     const localDateElementsGraphed = [] //Doing this to avoid depreciation warning
//     const localDateElements = [];
//     const countElements = [];
//     const currentDate = new Date();

//     //Set X axis labels as the previous 7 days
//     for (let i = 6; i >= 0; i--) {
      
//       const newDateLocal = new Date();
//       newDateLocal.setDate(currentDate.getDate());
//       const localDate = new Date();
//       localDate.setDate(localDate.getDate() - i);
//       const localDateOnly = localDate.toLocaleDateString();
      
//       localDateElements.push(localDateOnly);

//       //Fix for depreciation warning on non-ISO date format
//       const localDateFix = new Date(); // Assuming this is your local date object
//       const timezoneOffsetMs = localDateFix.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
//       const localDateWithOffset = new Date(localDate.getTime() - timezoneOffsetMs);
//       const isoString = localDateWithOffset.toISOString();
//       const isoStringDateOnly = isoString.split("T")[0];

//       localDateElementsGraphed.push(isoStringDateOnly);
      
//     }
    
//     //Attribute each quanity entry to a date, matched to local timezone
//     for (let i = 0; i < localDateElements.length; i++) {
//       var consumeCount = 0;
//       for (let j = 0; j < jsonItems.length; j++) {
//         const jsonDate = jsonItems[j].dt;
//         const localDate = new Date(jsonDate);
//         const localDateOnly = localDate.toLocaleDateString();
        
//         if (localDateOnly == localDateElements[i]) {
//           consumeCount+=parseInt(jsonItems[j].quantity);  //Parse int is needed here for parsing the "string" quantities posted by the ESP32 modules
//         }

//       }
//       countElements.push(consumeCount);
//     }

//     //Set Graph Components
//     const graphData = {
//       labels: localDateElementsGraphed,
//       datasets: [
//         {
//           label: 'Water Consumption',
//           data: countElements,
//           fill: true,
//           borderColor: 'rgb(75, 192, 192)',
//           tension: 0.1
//         }
//       ]
//     };

//     return graphData;
// }

// async function GetUserSettings(passedName) {
//   apiReadUserSettings.payload.Key['id'] = passedName;

//   var response = await fetch('https://w75zszy1n7.execute-api.us-east-2.amazonaws.com/test/dynamodbmanager', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(apiReadUserSettings)
//   }); 

//   const jsonData = await response.json();

//   var returnValue
//   if (jsonData.hasOwnProperty("Item")) {
//     returnValue = jsonData.Item;
//     waterQTY = jsonData.Item.container;
//     goalQTY = jsonData.Item.goal;
//   }
//   else {
//     returnValue = ''
//   }

//   return returnValue;
  
// }


// const App = ({ signOut, user }) => {


//   const [formState, setFormState] = useState(initialState)
//   const [todos, setTodos] = useState([])

//   //const [data, setGraphData] = useState(null);

//   //const [formData, setFormData] = useState({ name: '', email: '' });
//   const [formData, setFormData] = useState({ name: ''});

//   const [jsonDisplay, setJsonData] = useState(null);

//   // useEffect(() => {
//   //   fetchTodos()
//   // }, [])

//   const [data, setGraphData] = useState(null);

//   useEffect(() => {
//     fetchUserDetails();
//   }, [])

//   const fetchUserDetails = async () => {
    
//     try {
//       const response = await GetUserSettings(user.username);

//       const currentDate = new Date();
//       const fromDate = new Date();
//       fromDate.setDate(currentDate.getDate() - 7);
//       isoString_from_date = fromDate.toISOString();
//       const graphData = await QueryOnUser(user.username, isoString_from_date);
//       setGraphData(graphData);

//     } catch (error) {
//       console.error('Error fetching user data', error);
//     }
      
//   };


//   // if (isFirstRun) {
//   //   const response = GetUserSettings(user.username);
//   //   console.log(response);

//   //   const currentDate = new Date();
//   //   const fromDate = new Date();
//   //   fromDate.setDate(currentDate.getDate() - 7);
//   //   isoString_from_date = fromDate.toISOString();
//   //   const graphData = QueryOnUser(user.username, isoString_from_date);
//   //   console.log(graphData);
//   //   setGraphData(graphData);
//   //   console.log(data);
//   //   isFirstRun = false;
//   // }

//   function setInput(key, value) {
//     setFormState({ ...formState, [key]: value })
//   }

//   const handleSubmit = async (event) => {
//     event.preventDefault();

    
//     idValue = user.username;

//     try {


//       const currentDate = new Date();
//       isoString_now = currentDate.toISOString();

//       const fromDate = new Date();
//       fromDate.setDate(currentDate.getDate() - 7);
//       isoString_from_date = fromDate.toISOString();
      

//       //Update Attributes of JSON values
//       apiStringRead.payload.Key['id'] = idValue;
//       apiStringPut.payload.Item['id'] = idValue;
//       apiStringPut.payload.Item['dt'] = isoString_now;
//       apiStringPut.payload.Item['quantity'] = waterQTY;
//       api_query_user_since_date.payload.ExpressionAttributeValues[":partitionValue"] = idValue;
//       api_query_user_since_date.payload.ExpressionAttributeValues[":sortValue"] = isoString_from_date;


//       //Create Entry in Database
//       var response = await fetch('https://w75zszy1n7.execute-api.us-east-2.amazonaws.com/test/dynamodbmanager', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify(apiStringPut)
//       }); 
      

//       const graphData = await QueryOnUser(idValue, isoString_from_date);
//       setGraphData(graphData);

      
//       // Handle the response as needed
//       if (response.ok) {
//         // Request was successful
//         console.log('POST request succeeded');
//         //console.log(jsonData.Count);
//         //console.log(jsonData.Items);
//       } else {
//         // Request was not successful
//         console.log('POST request failed');
//       }
//     } catch (error) {
//       console.error('Error making POST request:', error);
//     }
    

    
//   };


//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
//   };

//   const handleGraphUpdate = async (event) => {

//     event.preventDefault();

//     console.log(user.username);

//     idValue = user.username;
//     const currentDate = new Date();
//     const fromDate = new Date();
//     fromDate.setDate(currentDate.getDate() - 7);
//     isoString_from_date = fromDate.toISOString();
//     const response = await QueryOnUser(idValue, isoString_from_date);
//     setGraphData(response);

//     // if (formData.name != '') {

//     //   idValue = TrimName(formData.name);
//     //   const currentDate = new Date();
//     //   const fromDate = new Date();
//     //   fromDate.setDate(currentDate.getDate() - 7);
//     //   isoString_from_date = fromDate.toISOString();
//     //   const response = await QueryOnUser(idValue, isoString_from_date);
//     //   setGraphData(response);
//     // }

    

//   };

//   async function fetchTodos() {
//     try {
//       const todoData = await API.graphql(graphqlOperation(listTodos))
//       const todos = todoData.data.listTodos.items
//       setTodos(todos)
//     } catch (err) { console.log('error fetching todos') }
//   }

//   async function addTodo() {
//     try {
//       if (!formState.name || !formState.description) return
//       const todo = { ...formState }
//       setTodos([...todos, todo])
//       setFormState(initialState)
//       await API.graphql(graphqlOperation(createTodo, {input: todo}))
//     } catch (err) {
//       console.log('error creating todo:', err)
//     }
//   }

//   return (
//     <div className="background-container">
//       <div className="sign-out-button">
//         <form onClick={signOut}>
//           <button type="button">Sign Out</button>
//         </form>
//       </div>
//       <div className="container">
//         <div className="translucent-frame">
//           <div className="title-content">
//             <link rel="shortcut icon" type="image/x-icon" href="https://cdn.discordapp.com/attachments/1121243216184872970/1122586567580586015/logo.png" />
//             <h1>Udra</h1>
//             <div className="circle-image">
//               <img src="https://cdn.discordapp.com/attachments/1121243216184872970/1122586567580586015/logo.png" alt="Udra Logo"></img>
//             </div>
//             <h1>{user.username}</h1>
//           </div>
//           <div className="settings-box">
//             <p>Bottle Size: {waterQTY} oz</p>
//             <p>Daily Goal: {goalQTY} oz</p>
//           </div>
//           <br />
//           <div className="content">
//             <div className='side-by-side-div'>
//               <div className='refill-button'>
//                 <form onSubmit={handleSubmit}>
//                   {/* <label>
//                     Name:
//                     <input
//                       type="text"
//                       name="name"
//                       value={formData.name}
//                       onChange={handleChange}
//                     />
//                   </label>
//                   <br />
//                   <br /> */}
//                   <button className='refill-button-image' type="submit"></button>
//                 </form>
//                 <div className='under-button-text'>
//                   <p>Refill Bottle ({waterQTY} oz)</p>
//                 </div>
//               </div>
//             </div>
//             <div>
//               {data ? (
//                   <div className="graph-content">
//                     <Line data={data} options={options} />
//                   </div>
//                 ) : (
//                   <p></p>
//                 )}
              
//             </div>
//           </div>
          
          
//         </div>
        
//       </div>
      
//     </div>
//     // <View style={styles.container}>
//     //   <Heading level={1}>Hello {user.username}</Heading>
//     //   <Button style={styles.button}onClick={signOut}>Sign out</Button>
//     //   {/* <Heading level={2}>Amplify Todos</Heading>
//     //   <TextField
//     //     placeholder="Name"
//     //     onChange={event => setInput('name', event.target.value)}
//     //     style={styles.input}
//     //     defaultValue={formState.name}
//     //   />
//     //   <TextField
//     //     placeholder="Description"
//     //     onChange={event => setInput('description', event.target.value)}
//     //     style={styles.input}
//     //     defaultValue={formState.description}
//     //   />
//     //   <Button style={styles.button} onClick={addTodo}>Create Todo</Button>
//     //   {
//     //     todos.map((todo, index) => (
//     //       <View key={todo.id ? todo.id : index} style={styles.todo}>
//     //         <Text style={styles.todoName}>{todo.name}</Text>
//     //         <Text style={styles.todoDescription}>{todo.description}</Text>
//     //       </View>
//     //     ))
//     //   } */}
      
//     // </View>
    
//   )
// }

// const styles = {
//   container: { width: 400, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
//   todo: {  marginBottom: 15 },
//   input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
//   todoName: { fontSize: 20, fontWeight: 'bold' },
//   todoDescription: { marginBottom: 0 },
//   button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
// }

// export default withAuthenticator(App);