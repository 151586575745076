import React from 'react';

const Beta = () => {
  return (
    <div className="beta-container">
      {/* Welcome Section */}
      <section className="welcome-section">
        <h1>Welcome Udra Beta Testers!</h1>
        <p>Thank you for participating in the beta test of our app. Your feedback is invaluable to us and will help improve the overall user experience. Please explore the app and let us know your thoughts.</p>
      </section>

      {/* Tasks Section */}
      <section className="tasks-section">
        <h2>Tasks to Focus On</h2>
        <ul>
          <li>Placeholder</li>
          <li>Placeholder</li>
          <li>Placeholder</li>
          {/* Add more tasks as needed */}
        </ul>
      </section>

      {/* How-to Section */}
      <section className="how-to-section">
        <h2>How to Give Feedback</h2>
        <p>When providing feedback, please be as specific as possible. Mention the feature, the issue, and the steps to reproduce it. Screenshots are always helpful!</p>
        <p>You can submit your feedback through our feedback form, directly on TestFlight, or send an email to <a href="mailto:tcham73@gmail.com">tcham73@gmail.com</a>.</p>
      </section>
    </div>
  );
};

export default Beta;

